<template>
  <v-card v-if="(selectedHost || {}).invitationInfo" class="host-invitation-info">
    <v-toolbar-title class="pl-4 pt-4">Invitation Info</v-toolbar-title>
    <v-list class="list-info">
      <v-list-item
        v-if="selectedHost.invitationInfo.adminInvitationEmail"
        class="list-info-item admin-invitation-email"
      >
        <v-list-item-subtitle>Admin Invitation Email</v-list-item-subtitle>
        <v-list-item-title>
          {{ selectedHost.invitationInfo.adminInvitationEmail }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="selectedHost.invitationInfo.adminName"
        class="list-info-item admin-name"
      >
        <v-list-item-subtitle>Admin Name</v-list-item-subtitle>
        <v-list-item-title>
          {{ selectedHost.invitationInfo.adminName }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="selectedHost.invitationInfo.adminPrimaryAddress"
        class="list-info-item admin-primary-address"
      >
        <v-list-item-subtitle>Admin Primary Address</v-list-item-subtitle>
        <v-list-item-title>
          {{ selectedHost.invitationInfo.adminPrimaryAddress }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="selectedHost.invitationInfo.adminSecondaryAddress"
        class="list-info-item admin-secondary-address"
      >
        <v-list-item-subtitle>Admin Secondary Address</v-list-item-subtitle>
        <v-list-item-title>
          {{ selectedHost.invitationInfo.adminSecondaryAddress }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="selectedHost.invitationInfo.adminCity"
        class="list-info-item admin-city"
      >
        <v-list-item-subtitle>Admin City</v-list-item-subtitle>
        <v-list-item-title>
          {{ selectedHost.invitationInfo.adminCity }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="selectedHost.invitationInfo.adminState"
        class="list-info-item admin-state"
      >
        <v-list-item-subtitle>Admin State</v-list-item-subtitle>
        <v-list-item-title>
          {{ selectedHost.invitationInfo.adminState }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="selectedHost.invitationInfo.adminCountry"
        class="list-info-item admin-country"
      >
        <v-list-item-subtitle>Admin Country</v-list-item-subtitle>
        <v-list-item-title>
          {{ selectedHost.invitationInfo.adminCountry }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="selectedHost.invitationInfo.adminZip"
        class="list-info-item admin-zip"
      >
        <v-list-item-subtitle>Admin Zip</v-list-item-subtitle>
        <v-list-item-title>
          {{ selectedHost.invitationInfo.adminZip }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="selectedHost.invitationInfo.initialInviteAt"
        class="list-info-item initial-invite-at"
      >
        <v-list-item-subtitle>Initial Invite At</v-list-item-subtitle>
        <v-list-item-title>
          {{ initialInviteAt }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="selectedHost.invitationInfo.finalInviteAt"
        class="list-info-item final-invite-at"
      >
        <v-list-item-subtitle>Final Invite At</v-list-item-subtitle>
        <v-list-item-title>
          {{ finalInviteAt }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { dateTimeMomentFormat, HOST_STATUS_DRAFT } from "../../utils/utils";

export default {
  name: "HostInvitationInfo",

  data: () => ({
    HOST_STATUS_DRAFT,
  }),

  computed: {
    ...mapState("hostModule", ["selectedHost"]),

    initialInviteAt() {
      return (this.selectedHost || {}).invitationInfo
        ? moment(this.selectedHost.invitationInfo.initialInviteAt)
          .format(dateTimeMomentFormat) : "";
    },

    finalInviteAt() {
      return (this.selectedHost || {}).invitationInfo
        ? moment(this.selectedHost.invitationInfo.finalInviteAt)
          .format(dateTimeMomentFormat) : "";
    },
  },
};
</script>

<style lang="scss">
.host-invitation-info {
  .list-info {
    display: flex;
    flex-wrap: wrap;
    .list-info-item {
      display: block !important;
      flex: auto;
    }
  }
}
</style>
